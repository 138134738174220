import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Table, Icon, Grid, Button, Popup, Modal, Dropdown } from "semantic-ui-react";
import { getRequest, postRequest } from "../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import CustomTable from "../CustomTable";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { StateContext } from "../State";
import StateDropdown from "../StateDropdown";
import { convertToFileDownload } from "../../helpers/ConvertToFileDownload";

function Websites({ history, match: { params } }) {
  const { selectedState, states } = useContext(StateContext);
  if (!params.state || params.state === "no_state") {
    history.replace(`websites/states/${selectedState || 31}`);
  }
  const [state, setState] = useState({
    states: [],
    initialLoading: true,
    noResults: false,
    websites: [],
    copied: false,
    confirmDelete: null
  });

  const [titleCompanies, setTitleCompanies] = useState([]);
  const [selectedTitleCompany, setSelectedTitleCompany] = useState(1);

  async function getStates() {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      setState(state => ({
        ...state,
        states: result.data
      }));
    }
  }

  async function getTitleCompanies() {
    const result = await getRequest("/titleCompany/getTitleCompanies");
    if (result.success) {
      var titleCompanies = [
        {
          key: null,
          value: null,
          text: "All"
        },
        ...result.data.map(t => ({
          key: t.id,
          value: t.id,
          text: t.name
        }))
      ];
      setTitleCompanies(titleCompanies);
    }
  }

  async function getWebsitesByState() {
    const result = await getRequest("/website/getWebsitesByState", {
      stateId: selectedState
    });
    if (result.success) {
      setState(state => ({
        ...state,
        websites: result.data,
        initialLoading: false,
        websiteToDelete: null
      }));
    }
  }

  async function deleteWebsite(websiteId) {
    const result = await postRequest(`/website/deleteWebsite?id=${websiteId}`);
    if (result.success) {
      setState(state => ({
        ...state,
        websites: state.websites.filter(w => w.id !== state.websiteToDelete.id),
        websiteToDelete: null
      }));
    }
  }

  function format(input) {
    const date = new Date(input.replace(/ /g, "T"));
    return (
      [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ][date.getMonth()] +
      " " +
      date.getDate() +
      ", " +
      date.getFullYear()
    );
  }

  async function download(id) {
    const result = await getRequest(`/website/downloadWebsiteFile?id=${id}`);
    if (result.success) {
      convertToFileDownload(result.data.base64File, result.data.name);
    }
  }

  useEffect(() => {
    getStates();
    getTitleCompanies();
  }, []);

  useEffect(() => {
    getWebsitesByState();
  }, [selectedState]);

  function alertCopied() {
    setState(state => ({ ...state, copied: true }));
    setTimeout(() => setState(state => ({ ...state, copied: false })), 3000);
  }

  const filteredWebsites = !selectedTitleCompany
    ? state.websites
    : state.websites.filter(w => w.titleCompanyId === selectedTitleCompany);

  return (
    <div className={css({ marginBottom: 40 })}>
      <StateDropdown pushHistory={value => history.push(`/websites/states/${value}`)} />
      <div className={css({ marginTop: 48 })}></div>
      <Grid>
        <Grid.Row columns={2} verticalAlign="middle">
          <Grid.Column floated="left">
            <div
              className={css({
                fontFamily: "Fakt-Bold",
                fontSize: 20,
                color: "#000e48",
                marginBottom: 40,
                marginTop: 40
              })}
            >
              Websites
              <Dropdown
                size="large"
                selection
                clearable
                placeholder="Select Title Company"
                style={{ fontSize: 16 }}
                options={titleCompanies}
                onChange={(_, { value }) => setSelectedTitleCompany(value)}
                className={css({ display: "inline-block", marginLeft: 16 })}
                defaultValue={selectedTitleCompany}
              />
            </div>
          </Grid.Column>
          <Grid.Column floated="right" className={css({ textAlign: "right" })}>
            <Button icon className="blueButton" onClick={() => history.push(`/websites/new-website`)}>
              <i className="fal fa-plus" />
            </Button>
            <span
              className={css({
                fontFamily: "Fakt-Bold",
                fontSize: 14,
                color: "#000",
                cursor: "pointer",
                "&:hover": {
                  color: "#00b8d4"
                }
              })}
              onClick={() => history.push(`/websites/new-website`)}
            >
              New Website
            </span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {!state.initialLoading && (
        <CustomTable
          customStyles={{
            "& tr td:first-of-type": {
              paddingLeft: "72px !important"
            },
            "& th": {
              position: "sticky",
              top: 0,
              zIndex: 1
            },
            "tr:first-of-type > th:first-of-type": {
              paddingLeft: "72px !important",
              textAlign: "left"
            },
            "& tr td": {
              verticalAlign: "top",
              paddingRight: "16px !important",
              paddingTop: "25px !important",
              paddingBottom: "25px !important",
              paddingLeft: "53px !important"
            }
          }}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={5}>Website</Table.HeaderCell>
              <Table.HeaderCell width={4}>User ID and Password</Table.HeaderCell>
              <Table.HeaderCell width={7} style={{ textAlign: "left" }}>
                Other Information
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {filteredWebsites.length < 1 && (
              <Table.Row>
                <Table.Cell>No websites available</Table.Cell>
              </Table.Row>
            )}
            {filteredWebsites.length >= 1 &&
              filteredWebsites.map(w => (
                <Table.Row key={w.id}>
                  <Table.Cell
                    className={css({
                      position: "relative"
                    })}
                  >
                    <React.Fragment>
                      <span className={css({ position: "absolute", left: 20 })}>
                        <span
                          className={css({
                            "&:hover i": { color: "#00b8d4" },
                            cursor: "pointer",
                            verticalAlign: "top",
                            display: "inline-block",
                            marginTop: 2
                          })}
                          onClick={() => history.push(`/websites/edit-website/${w.id}`)}
                        >
                          <Icon
                            style={{
                              marginRight: 5,
                              color: "#8E8E93",
                              cursor: "pointer"
                            }}
                            className="fal fa-pen"
                          />
                        </span>
                        <span
                          className={css({
                            "&:hover i": { color: "#00b8d4" },
                            cursor: "pointer",
                            verticalAlign: "top",
                            display: "inline-block",
                            marginTop: 2
                          })}
                          onClick={() =>
                            setState(state => ({
                              ...state,
                              websiteToDelete: w
                            }))
                          }
                        >
                          <Icon className="fal fa-trash" style={{ color: "#8E8E93", cursor: "pointer" }} />
                        </span>
                      </span>
                    </React.Fragment>
                    <div
                      className={css({
                        fontFamily: "FaktSlab-Bold",
                        fontSize: 16,
                        textAlign: "left"
                      })}
                    >
                      {w.url && (
                        <React.Fragment>
                          {w.titleCompany?.name && (
                            <div
                              className={css({
                                fontFamily: "FaktSlab-Normal",
                                fontSize: 16,
                                color: "rgb(142, 142, 147)",
                                marginBottom: 8
                              })}
                            >
                              {w.titleCompany?.name}
                            </div>
                          )}
                          <a
                            className={css({
                              cursor: "pointer",
                              textDecoration: "underline",
                              "&:hover": {
                                textDecoration: "underline",
                                color: "#00b8d4"
                              }
                            })}
                            href={w.url ? (w.url.match(/^https?:\/\//i) ? w.url : "http://" + w.url) : ""}
                            target="_blank"
                          >
                            {w.name}
                          </a>
                          <br />
                        </React.Fragment>
                      )}
                      {w.websiteFiles &&
                        w.websiteFiles.length > 0 &&
                        w.websiteFiles.map(f => (
                          <div>
                            <a
                              onClick={() => download(f.id)}
                              className={css({
                                textDecoration: "underline",
                                cursor: "pointer",
                                "&:hover": {
                                  textDecoration: "underline",
                                  color: "#00b8d4"
                                },
                                color: "rgb(142,142,147)"
                              })}
                            >
                              {f.fileName}
                              <i className="fal fa-paperclip" style={{ color: "#00b8d4", marginLeft: 8 }} />
                            </a>
                          </div>
                        ))}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    {w.username && (
                      <Popup
                        content={state.copied ? "Copied!" : "Click to copy"}
                        size="mini"
                        trigger={
                          <div
                            className={css({
                              textAlign: "left"
                            })}
                          >
                            <CopyToClipboard text={w.username} onCopy={() => alertCopied()}>
                              <span>
                                <i
                                  className="fal fa-user"
                                  style={{
                                    marginRight: 16,
                                    color: "#8E8E93",
                                    verticalAlign: "top",
                                    marginTop: 3
                                  }}
                                />
                                <span
                                  className={css({
                                    display: "inline-block",
                                    wordBreak: "break-word",
                                    maxWidth: 190
                                  })}
                                >
                                  {w.username}
                                </span>
                              </span>
                            </CopyToClipboard>
                          </div>
                        }
                      />
                    )}
                    {w.password && (
                      <Popup
                        content={state.copied ? "Copied!" : "Click to copy"}
                        size="mini"
                        trigger={
                          <div className={css({ textAlign: "left" })}>
                            <CopyToClipboard text={w.password} onCopy={() => alertCopied()}>
                              <span>
                                <i className="fal fa-unlock-alt" style={{ marginRight: 16, color: "#8E8E93" }} />
                                {w.password}
                              </span>
                            </CopyToClipboard>
                          </div>
                        }
                      />
                    )}
                    {(w.username || w.password) && (
                      <React.Fragment>
                        <div
                          className={css({
                            textAlign: "left",
                            marginLeft: -22,
                            fontSize: 12,
                            marginTop: 9
                          })}
                        >
                          <span
                            className={css({
                              marginRight: 12,
                              color: "rgb(142, 142, 147)"
                            })}
                          >
                            Edited:
                          </span>
                          {w.editedBy}
                        </div>
                        <div
                          className={css({
                            fontSize: 12,
                            textAlign: "left",
                            marginLeft: 27
                          })}
                        >
                          {format(w.editedDate)}
                        </div>
                      </React.Fragment>
                    )}
                  </Table.Cell>
                  <Table.Cell style={{ textAlign: "left", whiteSpace: "pre-line" }}>{w.otherInformation}</Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </CustomTable>
      )}
      {state.websiteToDelete && (
        <Modal
          size={"tiny"}
          open={state.websiteToDelete}
          onClose={() => setState(state => ({ ...state, websiteToDelete: null }))}
          dimmer="inverted"
        >
          <Modal.Header>Delete Website</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete <strong>{state.websiteToDelete.name || state.websiteToDelete.url}</strong>{" "}
              ?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() => setState(state => ({ ...state, websiteToDelete: null }))}
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteWebsite(state.websiteToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
}

export default withRouter(Websites);
